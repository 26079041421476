let
  detector,
  detectionCanvas,
  detectionContext,
  cameraFeed,
  beepSound,
  requestId = null,
  detectionActive = false,
  cameraModusActive = false,
  currentDataIndex = 0,
  inputCameraCode = null;

function createDetector() {
  detectionCanvas = document.getElementById("detection-canvas");
  detectionContext = detectionCanvas.getContext("2d");
  cameraFeed = document.getElementById("camera-feed");

  beepSound = document.createElement('audio');
  beepSound.setAttribute('src', '/zbar/beep.mp3');

  let supportedFormats = window['BarcodeDetector'].getSupportedFormats();
  detector = new window['BarcodeDetector']({
    formats: supportedFormats.value,
    zbar: { encoding: 'utf-8' }
  });
}

function startCodeDetection() {
  $('#camera-scanner').find('.video-container').append('<div class="loader"></div>');

  if (detector === undefined) {
    createDetector();
  }

  detectionActive = true;

  $('#camera-scanner').find('.alert').addClass('d-none');
  $('.item-block:not(.d-none)').addClass('d-none');
  $('#camera-scanner').removeClass('d-none');

  navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: "environment"
    }
  }).then((stream) => {
    cameraFeed.srcObject = stream;
    cameraFeed.onloadedmetadata = () => {
      cameraFeed.play();
      $('#camera-scanner').find('.loader').remove();
      detectCode();
    };
  })
  .catch((error) => {
    detectionActive = false;
    setCameraModus(false);

    $('#scanner-modal').modal('hide');
    dataConfirmModal.confirm({
      title: 'Achtung!',
      text: "Kamera konnte nicht gestartet werden. Bitte erlauben Sie den Zugriff auf die Kamera."
    });
    $('button').remove('.cancel');

    console.log("Error accessing camera:", error);
  });
}

function stopCodeDetection() {
  if (!detectionActive) return;

  detectionActive = false;
  detectionContext.clearRect(0, 0, detectionCanvas.width, detectionCanvas.height);
  cameraFeed.srcObject.getTracks().forEach((track) => {
    track.stop();
  });
  cameraFeed.srcObject = null;
  cancelAnimationFrame(requestId);
  requestId = null;
}

function detectCode() {
  if (detectionActive) {
    detector
      .detect(cameraFeed)
      .then(symbols => {
        detectionCanvas.width = cameraFeed.naturalWidth || cameraFeed.videoWidth || cameraFeed.width;
        detectionCanvas.height = cameraFeed.naturalHeight || cameraFeed.videoHeight || cameraFeed.height;
        detectionContext.clearRect(0, 0, detectionCanvas.width, detectionCanvas.height);

        if (symbols.length > 0) {
          const symbol = symbols[0];
          const lastCornerPoint = symbol.cornerPoints[symbol.cornerPoints.length - 1];
          detectionContext.moveTo(lastCornerPoint.x, lastCornerPoint.y)
          symbol.cornerPoints.forEach(point => detectionContext.lineTo(point.x, point.y))

          // Styling of the rectangle around the code
          detectionContext.lineWidth = 3;
          detectionContext.strokeStyle = 'rgba(69, 176, 255, 0.8)';
          detectionContext.stroke();

          // Handle detected code for modal camera scanner
          if ($('#scanner-modal').is(':visible') && inputCameraCode) {
            inputCameraCode.val(symbol.rawValue);
            beepSound.play();
            $('#scanner-modal').modal('hide');
            return;
          }

          // Else handle detected code for transactions
          if ($('.item-block[data-camera-code="' + symbol.rawValue + '"]').length > 0) {
            $('#camera-scanner').addClass('d-none');
            $('.item-block[data-camera-code="' + symbol.rawValue + '"]').removeClass('d-none');
            beepSound.play();
            stopCodeDetection();
            currentDataIndex = $('.item-block[data-camera-code="' + symbol.rawValue + '"]').data('step');
          } else {
            $('#camera-scanner').find('.alert').removeClass('d-none');
            $('#detected-code').text(symbol.rawValue);
          }
        }

        requestId = requestAnimationFrame(() => detectCode())
      })
      .catch((error) => {
        console.log("Error detecting barcode:", error);
      });
  }
}

function setCameraModus(status) {
  if (status) {
    if ($('.item-block[data-camera-code]').length < 1) {
      let errorText = "Es wurden in der Liste keine Artikel mit Code für Scanner gefunden.";
      dataConfirmModal.confirm({
        title: 'Achtung!',
        text: errorText,
      });
      $('button').remove('.cancel');

      return;
    }

    cameraModusActive = true;
    startCodeDetection();

    $(".camera-scanner-toggle-btn > .fa-toggle-off")
      .removeClass('fa-toggle-off')
      .addClass('fa-toggle-on')
      .addClass('text-primary');

    $('.continue-btn')
      .removeClass('item-transaction-btn')
      .addClass('continue-with-camera-btn')
      .text('Weiter mit Kamera');
  } else {
    cameraModusActive = false;
    stopCodeDetection();

    $(".camera-scanner-toggle-btn > .fa-toggle-on")
      .removeClass('text-primary')
      .removeClass('fa-toggle-on')
      .addClass('fa-toggle-off');

    $('.continue-with-camera-btn')
      .removeClass('continue-with-camera-btn')
      .addClass('item-transaction-btn')
      .text('Weiter');

    $('#camera-scanner').addClass('d-none');
    $('.item-block[data-step="' + currentDataIndex + '"]').removeClass('d-none');
  }
}

$(document).on("click", ".camera-scanner-toggle-btn", function () {
  setCameraModus(!cameraModusActive);
});

$(document).on("click", ".item-transaction-btn", function () {
  lastDataIndex = $('.item-block[data-step]:last').data('step');
  currentDataIndex = $(this).data('index');

  if (currentDataIndex == lastDataIndex) {
    setCameraModus(false);
    $('.camera-scanner-toggle-btn').addClass('d-none');
  } else {
    $('.camera-scanner-toggle-btn').removeClass('d-none');
  }
});

$(document).on("click", ".continue-with-camera-btn", function () {
  startCodeDetection();
});

$(document).on("click", ".camera-scanner-back-btn", function () {
  stopCodeDetection();

  $('#camera-scanner').addClass('d-none');
  $('.item-block[data-step="' + currentDataIndex + '"]').removeClass('d-none');
});

$(document).on("click", ".camera-scanner-list-btn", function () {
  currentDataIndex = $('.item-block[data-step]:last').data('step');
  setCameraModus(false);
  $('.camera-scanner-toggle-btn').addClass('d-none');
});

// Scan code with modal camera scanner for input field
$(document).on("click", ".scan-code-btn", function () {
  if ($(this).hasClass('disabled')) return;

  inputCameraCode = $(this).closest('.form-group').find('input');
  $('#scanner-modal').modal('show');
});

$(document).on('shown.bs.modal', '#scanner-modal', function () {
  startCodeDetection();
});

$(document).on('hide.bs.modal', '#scanner-modal', function (e) {
  stopCodeDetection();
})
