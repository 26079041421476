function sendRequest(source_filter) {
  let data = $(".storage_items_form").serialize();
  if (source_filter) {
    data += "&source_filter=" + source_filter;
  }

  $.ajax({
    type: 'GET',
    url: window.location.pathname,
    data: data,
    dataType: "script"
  });
}

$(document).on("turbolinks:load", function () {
  initializeStorageItemDatepickers()
});

$(document).on("change", ".storage_items_form select:not(#storage_transaction_item_branch_ids):not(#storage_transaction_item_storage_ids)", function () {
  sendRequest();
});

$(document).on("change", "#storage_transaction_item_branch_ids", function () {
  sendRequest("branches");
});

$(document).on("change", "#storage_transaction_item_storage_ids", function () {
  sendRequest("storages");
});

$(document).on("click", ".storage_items_form .btn-clear-datepicker", function() {
  $(this).closest(".form-group").find("input").val("");
  sendRequest();
});

function initializeStorageItemDatepickers() {
  let start_date = $("input.item_start_date").val();
  let end_date = $("input.item_end_date").val();

  $('input.item_start_date, input.item_end_date').datetimepicker("destroy");

  var start_date_options = window.options;
  var end_date_options = window.options;

  if (start_date !== "") {
    start_date_options = $.extend({}, window.options, { date: new Date(start_date) });
  }

  if (end_date !== "") {
    end_date_options = $.extend({}, window.options, { date: new Date(end_date) });
  }

  $("input.item_start_date").datetimepicker(start_date_options);
  $("input.item_end_date").datetimepicker(end_date_options);

  $("input#storage_transaction_item_start_date, input#storage_transaction_item_end_date").on("change.datetimepicker", function (event) {
    sendRequest();
  });
}

$(document).on('click', '.storage-items-file', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  window.open(url + "?" + form.serialize(), '_blank').focus();
})

$(document).on('click', '.storage-items-print', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  printJS({
    printable: url + "?" + form.serialize(),
    type: 'pdf',
    showModal: true
  })
})