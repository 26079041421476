import Chart from "chart.js/auto";

$(document).on("turbolinks:load", function () {
  initializeDatepicker();
  window.initializeCustomerChart();
});

$(window).on("resize", function() {
  window.initializeCustomerChart();
});

$(document).on("change", ".new_customer_statistic_chart", function (event) {
  sendRequest();
})

$(document).on("click", '#customer_statistic_chart .btn-clear-datepicker', function() {
  $(this).closest(".form-group").find("input").val("");
  sendRequest();
});

let customerChart = null;

window.initializeCustomerChart = function () {
  let element = $("#customer-statistic-chart canvas");
  element.html("");

  if (element.length > 0 && element.data("chart") != {}) {
    let context = element[0].getContext("2d");

    if (customerChart) {
      customerChart.destroy();
    }

    customerChart = new Chart(context, element.data("chart"));
  }
}

function initializeDatepicker() {
  let start_date = $("input#customer_statistic_chart_start_date").val();
  let end_date = $("input#customer_statistic_chart_end_date").val();

  $('input#customer_statistic_chart_start_date, input#customer_statistic_chart_end_date').datetimepicker("destroy");

  var start_date_options = {}
  var end_date_options = {}
  if (start_date !== "" && end_date !== "") {
    start_date_options = $.extend({}, window.options, { date: new Date(start_date), maxDate: new Date() });
    end_date_options = $.extend({}, window.options, { date: new Date(end_date), maxDate: new Date() });
  } else if (start_date !== "") {
    start_date_options = $.extend({}, window.options, { date: new Date(start_date), maxDate: new Date() });
    end_date_options = $.extend({}, window.options, { maxDate: new Date() });
  } else if (end_date !== "") {
    start_date_options = $.extend({}, window.options, { maxDate: new Date() });
    end_date_options = $.extend({}, window.options, { date: new Date(end_date), maxDate: new Date() });
  } else {
    start_date_options = $.extend({}, window.options, { maxDate: new Date() });
    end_date_options = $.extend({}, window.options, { maxDate: new Date() });
  }

  $("input#customer_statistic_chart_start_date").datetimepicker(start_date_options);
  $("input#customer_statistic_chart_end_date").datetimepicker(end_date_options);

  $("input#customer_statistic_chart_start_date, input#customer_statistic_chart_end_date").on("change.datetimepicker", function (event) {
    sendRequest();
  });
}

function sendRequest() {
  $.ajax({
    type: $(".new_customer_statistic_chart").attr("method"),
    url: $(".new_customer_statistic_chart").attr("action"),
    data: $(".new_customer_statistic_chart").serialize(),
    dataType: "script"
  });
}